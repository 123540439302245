import Storage, {accessTokenDomain, accountDomain} from "@/utils/storage";
import {api as UserApi} from "@/api/user";
import {useKeepAlive} from "@/store";
import router from "@/router";
import {api as WechatApi} from "@/api/wechat";
import {ResultCodeConstant} from "@/server/result-code.constant";
import {closeToast, showConfirmDialog, showToast} from "vant";
import {api as AccountApi} from "@/api/account";
import {closeWindow} from "@/utils/wxSDK";

/**
 * 自动重新登录，获取新token
 * 成功返回新accessToken对象
 * 失败返回null
 */
export const reLogin = () => {
  return new Promise(resolve => {
    const refreshToken = Storage.getRefreshToken();
    if (refreshToken) {
        UserApi.refreshToken({
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
        }).then(res => {
          const accessToken = loginSuccess(res);
          resolve(accessToken);
        }).catch(() => {
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });
}

/**
 * 登录成功，存储数据到localStorage
 * @param res 返回的响应体
 */
export const loginSuccess = (res) => {
  const accessToken: accessTokenDomain = {
    access_token: res.access_token,
    expires_in: res.expires_in,
    saveTime: Date.now()
  }
  Storage.setAccessToken(accessToken);
  Storage.setRefreshToken(res.refresh_token);
  return accessToken;
}

// 重新授权
export const reauthorization = () => {
  if (router.currentRoute.value.path == '/studentInfo') {
    Storage.clear(true);
    router.replace(router.currentRoute.value.path);
  } else if (router.currentRoute.value.path == '/homeWork' || router.currentRoute.value.path == '/growthRecord') {
    Storage.clear(true);
    router.replace({
      path: router.currentRoute.value.path,
      query: {
        name: router.currentRoute.value.query.name,
        shouldCloseWin: router.currentRoute.value.query.shouldCloseWin
      }
    });
  } else if (router.currentRoute.value.path == '/parentList') {
    Storage.clear(true);
    router.replace({
      path: router.currentRoute.value.path,
      query: {
        shouldCloseWin: router.currentRoute.value.query.shouldCloseWin
      }
    });
  } else {
    useKeepAlive().pushExclude('index');
    Storage.clear(true);
    router.replace('/index');
  }
}

// 判断本地是否有存储用户数据
export const hasUserInfo = () => {
  const account: accountDomain = Storage.getAccount();
  return account && JSON.stringify(account) !== '{}';
}

// token是否已不足半小时
export const tokenIsBeOverdue = () => {
  const accessToken: accessTokenDomain | object = Storage.getAccessToken();
  // token已被清空，直接返回已失效
  if (accessToken && JSON.stringify(accessToken) == '{}') return true;
  // token有效时间不足半小时，
  console.log((Date.now() - (accessToken as accessTokenDomain).saveTime) / 1000)
  console.log((accessToken as accessTokenDomain).expires_in - 1800)
  return ((Date.now() - (accessToken as accessTokenDomain).saveTime) / 1000 >= ((accessToken as accessTokenDomain).expires_in - 1800))
}

// 登录之前
export const loginBefore = async (route) => {
  if (Storage.getRefreshToken()) {
    const success = await reLogin();
    if (success) return true;
  }
  // 页面是已授权状态 进行登录
  if (route.query.type == 'register' && route.query.code) {
    // 通过code换取信息
    const authData = await authorization(route);
    let userData;
    let loginSuccess;
    // 授权成功 获取用户信息
    if (authData) {
      userData = await getUserInfo(authData);
    } else {
      getAccessTokenFail();
    }
    // 获取用户信息成功 登录获取令牌
    if (userData) {
      const account: accountDomain = {
        username: userData.userName,
        mobileNumber: userData.mobileNumber,
        id: userData.id,
        name: userData.name
      };
      Storage.setAccount(account);
      loginSuccess = await login(userData);
      closeToast();
    } else {
      getAccessTokenFail();
    }
    return loginSuccess;
  }
  // 还没授权 跳转页面进行授权
  else {
    wechatAuth();
    return false;
  }
}

// 获取用户信息
const getUserInfo = (authData) => {
  return new Promise(resolve => {
    AccountApi.parentAccountWechatLogin({
      wechatOpenId: authData.openid,
      userName: authData.unionid,
      name: authData.nickname
    }).then(res => {
      if (res.status == ResultCodeConstant.SUCCESS_CODE2) {
        resolve(res.data);
      } else {
        resolve(false);
      }
    }).catch(() => {
      resolve(false);
    })
  })
}

// 通过code换取信息
const authorization = (route) => {
  return new Promise(resolve => {
    const code = (route.query.code) as string;
    showToast({
      type: 'loading',
      message: '授权中...',
      duration: 0
    });
    WechatApi.access_token({
      code
    }).then(res => {
      if (res.status === ResultCodeConstant.SUCCESS_CODE2) {
        if (res.data.openid) {
          showToast({
            type: 'success',
            message: '授权成功！'
          });
          resolve(res.data);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    }).catch(() => {
      resolve(false);
    });
  })
}

// 登录
const login = (userData) => {
  return new Promise(resolve => {
    showToast({
      type: 'loading',
      message: '正在获取用户信息...',
      duration: 0
    });
    UserApi.login({
      username: userData.userName,
      password: userData.defaultPassword,
      grant_type: 'password'
    }).then(res => {
      loginSuccess(res);
      resolve(true);
    }).catch(() => {
      resolve(false);
    })
  })
}

export const logout = () => {
  Storage.clear();
  router.replace('/index');
}

// 授权失败 重新拉起授权
export const getAccessTokenFail = () => {
  showConfirmDialog({
    title: '操作',
    message: '授权失败，是否重试',
    confirmButtonColor: '#ff7222',
  }).then(() => {
    wechatAuth();
  }).catch(() => closeWindow());
}

// 微信授权
export const wechatAuth = () => {
  if (location.pathname != '/index' && location.pathname != '/studentInfo' && location.pathname != '/homeWork' && location.pathname != '/growthRecord' && location.pathname != '/parentList') return;
  let currentUrl = `${location.protocol}//${location.host}${location.pathname}`;
  if (location.search == "") {
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WECHAT_APP_ID}&redirect_uri=${currentUrl}?type=register&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
  } else {
    const params = new URLSearchParams(location.search);
    params.delete('type');
    params.delete('state');
    params.delete('code');
    currentUrl = encodeURIComponent(currentUrl + `?${params.toString()}&type=register`);
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WECHAT_APP_ID}&redirect_uri=${currentUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
  }
  // window.open(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_WECHAT_APP_ID}&redirect_uri=${currentUrl}?type=register&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`)
}
